import { format, formatDistanceToNow, subDays } from 'date-fns'
import { Button } from 'primereact/button'
import { ConfirmPopup } from 'primereact/confirmpopup'
import { Dialog } from 'primereact/dialog'
import { InputTextarea } from 'primereact/inputtextarea'
import { Toast } from 'primereact/toast'
import React, { useRef, useState } from 'react'
import { useGlobalContext } from '../../context'
import { Timeline } from 'primereact/timeline'
import capitalizeFirstLetter from '../../functions/capitalizeFirstLetter'
import classNames from 'classnames'
import { TabPanel, TabView } from 'primereact/tabview'
import { handleObjChange } from '../../functions/setter'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { Message } from 'primereact/message'
import { postOrderProductFollowup } from '../../api'

// OrderNoteDialog component to manage the order note dialog
function OrderNoteDialog({
  notesData,
  setNotesData,
  hideNotes,
  handleNotesDataChange,
  addOrderNote,
  deleteOrderNote,
  editOrderNote,
  orderProductFollowupRecord,
  setOrderProductFollowupRecord,
  isFollowupRecordsVisible = false,
  setDelayedOrderProductecords,
}) {
  /**
   * notesData @type {object} - object to check notes data
   * hideNotes @type {function} - function to hide notes
   * handleNotesDataChange @type {function} - function to handle notes data change
   * addOrderNote @type {function} - function to add order note
   * deleteOrderNote @type {function} - function to delete order note
   * editOrderNote @type {function} - function to edit order note
   **/

  const { user } = useGlobalContext()
  const toast = useRef(null)
  const [note, setNote] = useState({
    loading: false,
    text: '',
  })

  const followUpMediumOptions = [
    { name: 'Call', value: 'call' },
    { name: 'Sms', value: 'sms' },
    { name: 'Email', value: 'email' },
  ]

  const empty_addFollowUpForProductRecord = {
    opid: notesData?.meta?.targetId,
    followupMedium: '',
    issueAndResolution: '',
    status: notesData?.meta?.status,
    loading: false,
    estimatedTime: '',
    nextFollowupTime: '',
    clientFeedback: '',
  }
  const emptyErr = {
    state: false,
    errors: [],
  }

  const [addFollowUpForProductRecord, setAddFollowUpForProductRecord] = useState(
    empty_addFollowUpForProductRecord
  )
  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const [editOrderNoteData, setEditOrderNoteData] = useState({
    visible: false,
    loading: false,
    note: {},
    text: '',
  })
  const [deleteOrderNoteData, setDeleteOrderNoteData] = useState({
    visible: false,
    loading: false,
    note: {},
  })

  const handleNoteChange = (target, value) => {
    setNote((ps) => ({ ...ps, [target]: value }))
  }
  const hideNote = () => {
    setNote({
      loading: false,
      text: '',
    })
  }

  const handleEditOrderNoteChange = (target, value) => {
    setEditOrderNoteData((ps) => ({ ...ps, [target]: value }))
  }
  const hideEditOrderNote = () => {
    setEditOrderNoteData({
      visible: false,
      loading: false,
      note: {},
      text: '',
    })
  }

  const handleDeleteOrderNoteChange = (target, value) => {
    setDeleteOrderNoteData((ps) => ({ ...ps, [target]: value }))
  }
  const hideDeleteOrderNote = () => {
    setDeleteOrderNoteData({
      visible: false,
      loading: false,
      note: {},
    })
  }
  const hide = () => {
    hideNotes()
    hideNote()
  }

  const content = (item, index) => {
    let parseData = new Date(item?.createdAt)
    let date = formatDistanceToNow(parseData, { addSuffix: true })
    let date1 = format(parseData, 'PPPPp')

    return (
      <div key={item.id} className="p-mb-2">
        <h6 className=" p-text-bold p-mb-1">{`${capitalizeFirstLetter(
          item.status
        )} followup recorded via ${item.followupMedium}`}</h6>
        <p className="">
          <span>Note: </span>
          <label className="p-text-lowercase">{item?.note}</label>
        </p>
        <div>
          <p>
            <span>Performed By: </span>
            <label className="p-text-lowercase">
              {item?.master} ({item?.masterRole})
            </label>
          </p>
          <span>
            {date} - {date1}
          </span>
        </div>
      </div>
    )
  }

  const validateFollowUpForProductDetails = () => {
    const err = []
    const { issueAndResolution, followupMedium, estimatedTime, nextFollowupTime } =
      addFollowUpForProductRecord

    if (!issueAndResolution) {
      err.push('Adding an Issue & Resolution is mandatory')
    }
    if (!followupMedium.length) {
      err.push('Add a medium of communication')
    }
    if (!estimatedTime) {
      err.push('Please select estimated time of resolution')
    }
    if (!nextFollowupTime) {
      err.push('Please select next followup interval')
    }
    return err
  }

  const handleFollowUpForProduct = async () => {
    const errs = validateFollowUpForProductDetails()
    if (errs.length) {
      setNewRecordError({
        state: true,
        errors: errs,
      })
      return
    }
    handleObjChange(setAddFollowUpForProductRecord, 'loading', true)
    const { followupMedium, issueAndResolution, estimatedTime, nextFollowupTime, clientFeedback } =
      addFollowUpForProductRecord

    let opid = notesData?.meta?.targetId
    let status = notesData?.meta?.status
    const res = await postOrderProductFollowup(opid, {
      followupMedium,
      note: issueAndResolution,
      status,
      estimatedTime,
      nextFollowupTime,
      clientFeedback,
    })
    if (res) {
      handleObjChange(setAddFollowUpForProductRecord, 'loading', false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })
        const _orderProductFollowupRecord = orderProductFollowupRecord
        _orderProductFollowupRecord.records = [
          ...res.data.followupRecord,
          ..._orderProductFollowupRecord.records,
        ]
        setOrderProductFollowupRecord(_orderProductFollowupRecord)

        const _notesData = notesData
        _notesData.notes = [...res.data.noteRecord, ..._notesData.notes]
        setNotesData(_notesData)
        setDelayedOrderProductecords((ps) => [
          ...ps.map((val) => {
            if (val.orderProductId === opid) {
              val.isFollowupDone = 1
            }
            return val
          }),
        ])
        hideAddFollowupForProductDialog()
      }
    }
  }
  const hideAddFollowupForProductDialog = () => {
    setNewRecordError(emptyErr)
    setAddFollowUpForProductRecord(empty_addFollowUpForProductRecord)
  }

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        visible={notesData.visible}
        onHide={hide}
        style={{ width: '60vw' }}
        header={`Notes for ${notesData?.meta?.target} #${notesData?.meta?.targetId}`}
        footer={
          <div>
            <Button label="Close" icon="pi pi-times" onClick={hide} className="p-button-text" />
          </div>
        }
      >
        <div className="p-grid">
          <div
            className={classNames('p-col-12', {
              'p-lg-7': isFollowupRecordsVisible,
              'p-lg-12': !isFollowupRecordsVisible,
            })}
          >
            <TabView>
              <TabPanel header="Add Notes">
                <div className="card p-fluid">
                  <h5>Add Note</h5>
                  <hr />
                  <div className="p-field">
                    <InputTextarea
                      autoResize
                      rows={3}
                      placeholder="Enter note"
                      value={note.text}
                      onChange={(e) => handleNoteChange('text', e.target.value)}
                    />
                  </div>
                  <div className="p-field">
                    <Button
                      label={note.loading ? 'Adding' : 'Add'}
                      loading={note.loading}
                      disabled={note.loading}
                      onClick={async () => {
                        handleNoteChange('loading', true)
                        const res = await addOrderNote(notesData.meta.targetId, note.text)
                        if (res) {
                          handleNoteChange('loading', false)
                          if (res.status === 201) {
                            const nv = [res.data.note, ...notesData.notes]
                            handleNotesDataChange('notes', nv)
                            handleNoteChange('text', '')
                            toast.current.show({
                              severity: 'success',
                              summary: 'Operation Successful',
                              detail: res.data.message,
                            })
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="Add Followup">
                <div className="card">
                  <div className="p-field ">
                    <label htmlFor="reason" className="">
                      Add Issue and Resolution <sup style={{ color: 'red' }}>*</sup>
                    </label>
                    <InputTextarea
                      id="reason"
                      rows={3}
                      cols={30}
                      value={addFollowUpForProductRecord.issueAndResolution}
                      onChange={(e) =>
                        handleObjChange(
                          setAddFollowUpForProductRecord,
                          'issueAndResolution',
                          e.target.value || ''
                        )
                      }
                      className="w-full p-mt-1"
                      placeholder="Start typing here..."
                    />
                  </div>
                  <div className="p-field">
                    <label htmlFor="medium" className="">
                      Enter your medium of communication <sup style={{ color: 'red' }}>*</sup>
                    </label>

                    <InputText
                      id="medium"
                      value={addFollowUpForProductRecord.followupMedium}
                      onChange={(e) =>
                        handleObjChange(
                          setAddFollowUpForProductRecord,
                          'followupMedium',
                          e.target.value || ''
                        )
                      }
                      className="w-full p-mt-1"
                      placeholder="Call, Sms, Email"
                    />
                  </div>
                  <div className="p-field">
                    <label htmlFor="client-feedback" className="">
                      Add client feedback
                    </label>
                    <InputTextarea
                      id="client-feedback"
                      rows={3}
                      cols={30}
                      value={addFollowUpForProductRecord.clientFeedback}
                      onChange={(e) =>
                        handleObjChange(
                          setAddFollowUpForProductRecord,
                          'clientFeedback',
                          e.target.value || ''
                        )
                      }
                      className="w-full p-mt-1"
                      placeholder="Start typing here..."
                    />
                  </div>
                  <div className="p-field p-fluid ">
                    <label className="p-d-block" htmlFor="expectedTAT">
                      Select Estimated Resolution Time <sup style={{ color: 'red' }}>*</sup>
                    </label>
                    <Calendar
                      id="ERT"
                      placeholder="Select ERT"
                      showTime
                      hourFormat="12"
                      minDate={subDays(new Date(), 0)}
                      value={addFollowUpForProductRecord.estimatedTime}
                      onChange={(e) => {
                        handleObjChange(
                          setAddFollowUpForProductRecord,
                          'estimatedTime',
                          e.value || ''
                        )
                        if (addFollowUpForProductRecord.nextFollowupTime === '') {
                          handleObjChange(
                            setAddFollowUpForProductRecord,
                            'nextFollowupTime',
                            e.value || ''
                          )
                        }
                      }}
                    />
                  </div>
                  <div className="p-field p-fluid ">
                    <label className="p-d-block" htmlFor="expectedTAT">
                      Next Followup <sup style={{ color: 'red' }}>*</sup>
                    </label>
                    <Calendar
                      id="next-followup"
                      placeholder="Select Next Followup Interval"
                      showTime
                      hourFormat="12"
                      minDate={subDays(new Date(), 0)}
                      value={addFollowUpForProductRecord.nextFollowupTime}
                      onChange={(e) => {
                        handleObjChange(
                          setAddFollowUpForProductRecord,
                          'nextFollowupTime',
                          e.value || ''
                        )
                      }}
                    />
                  </div>
                  <Button
                    className="w-full"
                    label="Submit"
                    disabled={addFollowUpForProductRecord.loading}
                    loading={addFollowUpForProductRecord.loading}
                    onClick={() => {
                      handleFollowUpForProduct()
                    }}
                  />
                  {newRecordError.state && (
                    <>
                      {newRecordError.errors.map((err, idx) => {
                        return (
                          <div key={idx} className="p-fluid p-filed p-col-12">
                            <Message text={err} severity="warn" sticky={true} />
                          </div>
                        )
                      })}
                      <div className="p-fluid p-field p-col-12">
                        <Button
                          type="button"
                          onClick={() => {
                            setNewRecordError(emptyErr)
                          }}
                          icon="pi pi-times"
                          label="Clear Warnings"
                          className="p-button-secondary"
                        ></Button>
                      </div>
                    </>
                  )}
                </div>
              </TabPanel>
            </TabView>
            <div className="card">
              <h5 className="p-m-0">Notes</h5>
            </div>
            <div className="p-grid p-formgrid">
              {notesData?.notes?.map((note, idx) => {
                return (
                  <div className="p-col-12" key={idx}>
                    <div className="card">
                      <p>{note?.note}</p>
                      <hr />
                      <div className="p-d-flex gap-1by2 p-ai-center p-jc-between">
                        <div className="p-d-flex gap-1by2 p-ai-center opacity-50">
                          {note?.id && <small>#{note?.id}</small>}
                          {note?.createdBy && <small>By: {note.createdBy}</small>}

                          {note?.updatedAt && (
                            <small>Updated At: {format(new Date(note.updatedAt), 'PPp')}</small>
                          )}
                        </div>
                        {user.username === note.createdBy && (
                          <>
                            <div className="p-d-flex gap-1">
                              <Button
                                icon="pi pi-pencil"
                                className="p-button-text"
                                onClick={() => {
                                  handleEditOrderNoteChange('note', note)
                                  handleEditOrderNoteChange('visible', true)
                                  handleEditOrderNoteChange('text', note.note)
                                }}
                              />

                              <Button
                                id={`deleteOrderNote-${note.id}`}
                                className="p-button-danger p-button-text"
                                icon="pi pi-trash"
                                onClick={() => {
                                  handleDeleteOrderNoteChange('note', note)
                                  handleDeleteOrderNoteChange('visible', true)
                                }}
                              />
                              <ConfirmPopup
                                target={document.getElementById(`deleteOrderNote-${note.id}`)}
                                visible={
                                  deleteOrderNoteData.visible &&
                                  deleteOrderNoteData.note?.id === note?.id
                                }
                                onHide={hideDeleteOrderNote}
                                message="Are you sure you want to delete this note?"
                                icon="pi pi-exclamation-triangle"
                                acceptClassName="p-button-danger"
                                accept={async () => {
                                  const res = await deleteOrderNote(deleteOrderNoteData.note?.id)
                                  if (res) {
                                    if (res.status === 200) {
                                      const nv = [...notesData.notes].filter(
                                        (n) => n.id !== res.data.id
                                      )
                                      handleNotesDataChange('notes', nv)
                                      toast.current.show({
                                        severity: 'success',
                                        summary: 'Operation Successful',
                                        detail: res.data.message,
                                      })
                                    }
                                  }
                                }}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          {isFollowupRecordsVisible && (
            <div className="p-col-12 p-lg-5">
              <div className="card">
                <h5 className="p-d-flex p-jc-between p-ai-center">
                  <span>Shipment Delay Followup Timeline</span>
                </h5>
                <hr />
                {orderProductFollowupRecord?.records?.length > 0 ? (
                  <div className="widget-timeline">
                    <div className="timeline-header p-d-flex p-jc-between p-ai-center"></div>
                    <div className="timeline-content">
                      <Timeline
                        value={orderProductFollowupRecord?.records}
                        content={content}
                        className="custimized-timeline"
                      />
                    </div>
                  </div>
                ) : (
                  <h5 className="p-text-center">
                    <i className="pi pi-exclamation-circle p-mx-2" />
                    No Followup History
                  </h5>
                )}
              </div>
            </div>
          )}
        </div>
        <Dialog
          visible={editOrderNoteData.visible}
          onHide={hideEditOrderNote}
          className="w-full"
          style={{ maxWidth: '600px' }}
          header="Edit Order Note"
          footer={
            <div className="p-d-flex p-jc-end">
              <Button
                label="Cancel"
                className="p-button-text"
                icon="pi pi-times"
                onClick={hideEditOrderNote}
              />
              <Button
                label={editOrderNoteData.loading ? 'Saving' : 'Save'}
                loading={editOrderNoteData.loading}
                disabled={editOrderNoteData.loading}
                onClick={async () => {
                  handleEditOrderNoteChange('loading', true)
                  const res = await editOrderNote(editOrderNoteData.note.id, editOrderNoteData.text)
                  if (res) {
                    handleEditOrderNoteChange('loading', false)
                    if (res.status === 200) {
                      const nv = [...notesData.notes]?.map((n) => {
                        if (n.id === res.data.id) {
                          return res.data.note
                        }
                        return n
                      })
                      handleNotesDataChange('notes', nv)
                      hideEditOrderNote()
                      toast.current.show({
                        severity: 'success',
                        summary: 'Operation Successful',
                        detail: res.data.message,
                      })
                    }
                  }
                }}
                icon="pi pi-save"
              />
            </div>
          }
        >
          <InputTextarea
            autoResize
            rows={5}
            placeholder="Enter the note"
            className="w-full"
            value={editOrderNoteData.text}
            onChange={(e) => handleEditOrderNoteChange('text', e.target.value)}
          />
        </Dialog>
      </Dialog>
    </>
  )
}

export default OrderNoteDialog
