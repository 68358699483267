import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  addOrderFollowUpNote,
  deleteOrderFollowUpNote,
  editOrderFollowUpNote,
  getOrderFollowUpNotes,
  getOrderProductFollowup,
  getOrderProductShipmentFollowups,
  getShippingTimelines,
  getTrackingIds,
  postOrderProductFollowup,
  updateOrderProductShipmentDelays,
} from '../../api'
import { Checkbox } from 'primereact/checkbox'
import copy from 'clipboard-copy'

import { DataTable } from 'primereact/datatable'
import { FilterMatchMode } from 'primereact/api'
import { Column } from 'primereact/column'
import { TabPanel, TabView } from 'primereact/tabview'
import { InputText } from 'primereact/inputtext'
import { Toolbar } from 'primereact/toolbar'
import { format, formatDistanceToNow, isValid, subDays } from 'date-fns'
import { useGlobalContext } from '../../context'
import OrderProductINSDSwitch from '../../components/mycomponents/OrderManagement/OrderProductINSDSwitch'
import { MultiSelect } from 'primereact/multiselect'
import { Dropdown } from 'primereact/dropdown'
import MyDatesPicker from '../../components/mini/MyDatesPicker'
import { assignDates, dateFilters, dtFilenameDate } from '../../functions/myDateFns'
import { Button } from 'primereact/button'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import ShipperShippingTimeDialog from '../../components/mycomponents/Logistic/ShipperShippingTimeDialog'
import { Badge } from 'primereact/badge'
import { Dialog } from 'primereact/dialog'
import OrderNoteDialog from '../../components/mini/OrderNoteDialog'
import ConfirmationDialogWithInput from '../../components/mycomponents/Dialog/ConfirmationDialogWithInput'
import { handleObjChange } from '../../functions/setter'
import { Message } from 'primereact/message'
import { SplitButton } from 'primereact/splitbutton'
import capitalizeFirstLetter from '../../functions/capitalizeFirstLetter'
import ExportButton from '../../components/mini/ExportButton'
import { Calendar } from 'primereact/calendar'
import { InputTextarea } from 'primereact/inputtextarea'
import classNames from 'classnames'

const isShipmentDelayEnabledOptions = [
  { name: 'Enabled', value: 1 },
  { name: 'Disabled', value: 0 },
]

const delayedOptions = [
  'Shipping Delayed',
  'Tracking Delayed',
  'Tracking Live Delayed',
  'Delivery Delayed',
]

const isFollowupDoneOptions = [
  {
    name: 'All',
    value: null,
  },
  {
    name: 'Completed',
    value: 1,
  },
  {
    name: 'Pending',
    value: 0,
  },
]

export default function DelayedOrdersPage() {
  const { agents, leadSource, allStatusGroups, shippingTags, toast } = useGlobalContext()
  const dt = useRef(null)
  const currentDateTime = new Date()
  const emptyErr = {
    state: false,
    errors: [],
  }

  const emtpy_orderProductFollowupRecord = {
    visible: false,
    records: [],
  }

  const [orderProductFollowupRecord, setOrderProductFollowupRecord] = useState(
    emtpy_orderProductFollowupRecord
  )

  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const empty_notesData = { visible: false, meta: {}, notes: [] }
  const [notesData, setNotesData] = useState(empty_notesData)
  const hideNotes = () => {
    setNotesData(empty_notesData)
  }
  const handleNotesDataChange = (target, value) => {
    setNotesData((ps) => ({ ...ps, [target]: value }))
  }
  const [dateCode, setDateCode] = useState(dateFilters[4].code)
  const [dateRange, setDateRange] = useState(null)
  const [dateFilter, setDateFilter] = useState(assignDates(dateFilters[4].code))

  const [dialogVisible, setDialogVisible] = useState(false)

  const [delayedOrderProductRecords, setDelayedOrderProductecords] = useState([])
  const [shipperTTRecords, setShipperTTRecords] = useState([])
  const [followupTableLoading, setFollowupTableLoading] = useState(false)
  const [shipperTTTableLoading, setShipperTTTableLoading] = useState(false)
  const [followupTableFilters, setFollowupTableFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    masterid: { value: null, matchMode: FilterMatchMode.IN },
    orderLeadSource: { value: null, matchMode: FilterMatchMode.IN },
    orderStatus: { value: null, matchMode: FilterMatchMode.IN },
    productStatus: { value: null, matchMode: FilterMatchMode.IN },
    shippingCompany: { value: null, matchMode: FilterMatchMode.IN },
    delayedFor: {
      value: null,
      matchMode: FilterMatchMode.IN,
    },
    isShipmentDelayEnabled: { value: 1, matchMode: FilterMatchMode.EQUALS },
    isFollowupDone: { value: null, matchMode: FilterMatchMode.EQUALS },
  })
  const [shipperTTTableFilters, setShipperTTTableFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  })
  const [expandedOrderFollowupRows, setExpandedOrderFollowupRows] = useState([])

  const fetchFollowupRecords = useCallback(async () => {
    setFollowupTableLoading(true)
    const res = await getOrderProductShipmentFollowups({
      dateFrom: dateFilter.dateFrom,
      dateTo: dateFilter.dateTo,
    })
    if (res) {
      setFollowupTableLoading(false)
      if (res.status === 200) {
        setDelayedOrderProductecords(res.data?.records || [])
      }
    }
  }, [dateFilter])

  const fetchShipperTimelines = async () => {
    setShipperTTTableLoading(true)
    const res = await getShippingTimelines()
    if (res) {
      setShipperTTTableLoading(false)
      if (res.status === 200) {
        setShipperTTRecords(res.data?.records || [])
      }
    }
  }

  useEffect(() => {
    fetchFollowupRecords()
  }, [fetchFollowupRecords])

  useEffect(() => {
    fetchShipperTimelines()
  }, [])

  const handleFilterChange = (setter, key, value) => {
    setter((ps) => ({ ...ps, [key]: { ...ps[key], value } }))
  }

  const handleCopy = async (text) => {
    copy(text)
    toast.current.show({
      severity: 'success',
      summary: 'Successfully copied',
    })
  }
  const onRowExpand = async ({ data }) => {
    const { orderProductId } = data
    let trackingRecords = []
    const trackingRes = await getTrackingIds({ orderProductId })
    if (trackingRes) {
      if (trackingRes.status === 200) {
        trackingRecords = trackingRes.data
        setDelayedOrderProductecords((ps) =>
          ps.map((i) =>
            i.orderProductId === orderProductId ? { ...i, trackingData: trackingRecords } : i
          )
        )
      }
    }
  }

  const rowExpansionTemplate = (rd) => (
    <div className="p-grid w-full">
      {/* <div className="p-col-12 p-lg-8">
        <DataTable
          value={[rd.productDetails]}
          emptyMessage="No product found"
          header="Product Details"
        >
          <Column header="ID" field="id"></Column>
          <Column header="Product Name" field="productName"></Column>
          <Column header="Strength" field="strength"></Column>
          <Column header="No. Pills" field="quantity"></Column>
          <Column header="Pill Price" field="quantityPrice"></Column>
          <Column header="No. Qty" field="totalQuantity"></Column>
          <Column header="Total Price" field="total"></Column>
          <Column header="Status" field="status"></Column>
          <Column header="Shipping" field="shippingCompany"></Column>
        </DataTable>
      </div> */}
      <div className="p-col-12 p-md-8 p-lg-6">
        <DataTable
          emptyMessage="No tracking found"
          value={rd.trackingData}
          header="Tracking Details"
        >
          <Column
            header="Tracking Id"
            field="trackingid"
            style={{
              width: '20%',
            }}
          />
          <Column
            header="Qty"
            field="quantity"
            style={{
              width: '15%',
            }}
          />
          <Column
            body={(d) => {
              return <Badge severity={d.status === 'Live' ? 'danger' : 'info'} value={d.status} />
            }}
            header="Status"
            field="status"
            style={{
              width: '15%',
            }}
          />
          <Column
            header="Updated At"
            body={(rd) =>
              rd?.updatedAt && isValid(new Date(rd.updatedAt))
                ? format(new Date(rd.updatedAt), 'PPp')
                : 'NA'
            }
            style={{
              width: '15%',
            }}
          />

          <Column
            body={(rd) => (
              <div className="p-d-flex gap-1by2 p-ai-center">
                <Link to={{ pathname: rd.trackinglink }} target="_blank">
                  <Button
                    icon="pi pi-map-marker"
                    className="p-button-rounded p-button-outlined"
                    aria-label="Open Link"
                    tooltip="Open Link"
                  />
                </Link>
                <Button
                  icon="pi pi-copy"
                  className="p-button-rounded p-button-outlined"
                  aria-label="Copy Link"
                  tooltip="Copy Link"
                  onClick={() => handleCopy(rd.trackinglink)}
                />
              </div>
            )}
            header="Link"
            style={{ width: '10%' }}
            field="status"
          />
        </DataTable>
      </div>
    </div>
  )

  const handleTrackingSwitch = (orderProductId, status) => {
    setDelayedOrderProductecords((ps) => [
      ...ps.map((item) => {
        if (item.orderProductId === orderProductId) {
          item.isShipmentDelayEnabled = status ? 1 : 0
        }
        return item
      }),
    ])
  }

  // const content = (item, index) => {
  //   let parseData = new Date(item?.createdAt)
  //   let date = formatDistanceToNow(parseData, { addSuffix: true })
  //   let date1 = format(parseData, 'PPPPp')

  //   return (
  //     <div key={item.id} className="p-mb-2">
  //       <h6 className=" p-text-bold p-mb-1">{`${capitalizeFirstLetter(
  //         item.status
  //       )} followup recorded via ${item.followupMedium}`}</h6>
  //       <p className="">
  //         <span>Note: </span>
  //         <label className="p-text-lowercase">{item?.note}</label>
  //       </p>
  //       <div>
  //         <p>
  //           <span>Performed By : </span>
  //           <label className="p-text-lowercase">
  //             {item?.master} ({item?.masterRole})
  //           </label>
  //         </p>
  //         <span>
  //           {date} - {date1}
  //         </span>
  //       </div>
  //     </div>
  //   )
  // }

  const actionDetails = async (rd) => {
    setFollowupTableLoading(true)
    const followupRes = await getOrderProductFollowup(rd.orderProductId)
    if (followupRes) {
      // setOpenIssuesTableLoading(false)
      if (followupRes.status === 200) {
        setOrderProductFollowupRecord({
          visible: true,
          records: followupRes.data.records,
        })
      }
    }
    const getNotesRes = await getOrderFollowUpNotes(rd.orderProductId)
    if (getNotesRes) {
      if (getNotesRes.status === 200) {
        setNotesData({
          visible: true,
          meta: {
            target: 'Order Product Shipment Delay Follow Up',
            targetId: rd.orderProductId,
            status: rd.delayedFor,
          },
          notes: getNotesRes.data,
        })
      }
    }
    setFollowupTableLoading(false)
  }

  const hideOrderProductFollowupRecord = () => {
    setOrderProductFollowupRecord(emtpy_orderProductFollowupRecord)
  }
  return (
    <div>
      <Toolbar
        className="p-toolbar"
        left={
          <div className="p-d-flex p-flex-wrap gap-1 p-ai-center">
            <h4 className="p-m-0">Shipment Delays</h4>
            <Button
              icon="pi pi-eye"
              label="Shipper Timelines"
              onClick={() => {
                setDialogVisible(true)
              }}
            />
          </div>
        }
        right={
          <div className="p-d-flex p-flex-wrap gap-1">
            <ExportButton datatable_ref={dt} />
            <Button icon="pi pi-refresh" onClick={fetchFollowupRecords} />
          </div>
        }
      />
      <div className="order-delays">
        <DataTable
          ref={dt}
          exportFilename={`Delayed_Orders_${dtFilenameDate}`}
          value={delayedOrderProductRecords}
          filters={followupTableFilters}
          showGridlines
          dataKey="orderProductId"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100]}
          className="datatable-responsive"
          columnResizeMode="fit"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
          emptyMessage="No record found."
          loading={followupTableLoading}
          rowHover
          removableSort
          expandedRows={expandedOrderFollowupRows}
          rowClassName={(rd) => {
            return {
              'row-isFollowUpPending': !rd.isFollowupDone,
            }
          }}
          // select only one row at a time
          onRowToggle={(e) => {
            setExpandedOrderFollowupRows(e.data)
          }}
          onRowExpand={onRowExpand}
          rowExpansionTemplate={rowExpansionTemplate}
          header={
            <div className="p-d-flex p-flex-wrap gap-1by2 p-jc-end">
              <div className="">
                <MultiSelect
                  options={agents}
                  value={followupTableFilters.masterid.value}
                  placeholder="Agent"
                  filter
                  filterBy="username,role"
                  filterPlaceholder="Search"
                  optionLabel="username"
                  optionValue="id"
                  showClear
                  onChange={(e) => {
                    handleFilterChange(setFollowupTableFilters, 'masterid', e.value)
                  }}
                  maxSelectedLabels={3}
                />
              </div>
              <div className="">
                <Dropdown
                  options={isShipmentDelayEnabledOptions}
                  value={followupTableFilters.isShipmentDelayEnabled.value}
                  placeholder="Tracking Filter"
                  optionLabel="name"
                  optionValue="value"
                  showClear
                  onChange={(e) => {
                    handleFilterChange(setFollowupTableFilters, 'isShipmentDelayEnabled', e.value)
                  }}
                  maxSelectedLabels={3}
                />
              </div>
              <div className="">
                <MultiSelect
                  options={leadSource}
                  value={followupTableFilters.orderLeadSource.value}
                  placeholder="Leads Source"
                  filter
                  optionLabel="name"
                  optionValue="value"
                  showClear
                  onChange={(e) => {
                    handleFilterChange(setFollowupTableFilters, 'orderLeadSource', e.value)
                  }}
                  maxSelectedLabels={3}
                />
              </div>
              <div>
                <MultiSelect
                  value={followupTableFilters.orderStatus.value}
                  options={allStatusGroups}
                  optionLabel="name"
                  optionGroupLabel="name"
                  optionGroupChildren="items"
                  optionValue="value"
                  filter
                  filterBy="name"
                  filterPlaceholder="Search..."
                  onChange={(e) => {
                    handleFilterChange(setFollowupTableFilters, 'orderStatus', e.value)
                  }}
                  placeholder="Order Status"
                  maxSelectedLabels={3}
                />
              </div>
              <div>
                <MultiSelect
                  value={followupTableFilters.productStatus.value}
                  options={allStatusGroups.filter((i) => i.name === 'Paid')}
                  optionLabel="name"
                  optionGroupLabel="name"
                  optionGroupChildren="items"
                  optionValue="value"
                  filter
                  filterBy="name"
                  filterPlaceholder="Search..."
                  onChange={(e) => {
                    handleFilterChange(setFollowupTableFilters, 'productStatus', e.value)
                  }}
                  placeholder="Product Status"
                  maxSelectedLabels={3}
                />
              </div>
              <div>
                <MultiSelect
                  options={shippingTags}
                  value={followupTableFilters.shippingCompany.value}
                  placeholder="Shipping"
                  showClear
                  onChange={(e) => {
                    handleFilterChange(setFollowupTableFilters, 'shippingCompany', e.value)
                  }}
                  maxSelectedLabels={3}
                />
              </div>
              <div>
                <MultiSelect
                  options={delayedOptions}
                  value={followupTableFilters.delayedFor.value}
                  placeholder="Delayed For"
                  filterPlaceholder="Search..."
                  showClear
                  onChange={(e) => {
                    handleFilterChange(setFollowupTableFilters, 'delayedFor', e.value)
                  }}
                  maxSelectedLabels={3}
                />
              </div>
              <div>
                <Dropdown
                  options={isFollowupDoneOptions}
                  value={followupTableFilters.isFollowupDone.value}
                  optionLabel="name"
                  optionValue="value"
                  placeholder="Followup"
                  filterPlaceholder="Search..."
                  showClear
                  onChange={(e) => {
                    handleFilterChange(setFollowupTableFilters, 'isFollowupDone', e.value)
                  }}
                  maxSelectedLabels={3}
                />
              </div>
              <div>
                <MyDatesPicker
                  code={dateCode}
                  setCode={setDateCode}
                  rangeDates={dateRange}
                  setRangeDates={setDateRange}
                  filterDates={dateFilter}
                  setFilterDates={setDateFilter}
                />
              </div>
              <div className="">
                <span style={{ width: '200px' }} className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText
                    type="search"
                    value={followupTableFilters.global.value}
                    onInput={(e) => {
                      let _filters = { ...followupTableFilters }
                      _filters.global.value = e.target.value
                      setFollowupTableFilters(_filters)
                    }}
                    placeholder="Search..."
                  />
                </span>
              </div>
            </div>
          }
        >
          <Column field="orderLeadSource" hidden />
          <Column expander={true} style={{ width: '0%' }} />
          <Column header="PID" field="orderProductId" sortable style={{ width: '0%' }} />
          <Column header="C. Name" field="customerName" />
          <Column header="Order By" field="orderBy" />
          <Column
            header="SC"
            field="shippingCompany"
            style={{ width: '0%' }}
            body={(rd) => {
              return (
                <Link
                  // target="_blank"
                  to={`/website/logistics?edit_st_code=${rd.shippingCompany}`}
                >
                  {rd.shippingCompany}
                </Link>
              )
            }}
          />
          <Column header="Product" field="product" />
          <Column
            header="OrderId"
            field="orderid"
            body={(rd) => (
              <Link target="_blank" to={`/orders?invId=${rd.orderid}`}>
                {rd.orderid}
              </Link>
            )}
          />
          <Column
            header="P. Date"
            field="processedDate"
            sortable
            body={(rd) => format(new Date(rd.processedDate), 'PP')}
          />
          <Column header="P.Days" field="daysSinceProcessed" sortable style={{ width: '0%' }} />
          <Column header="P. Status" field="productStatus" sortable />
          <Column header="O. Status" field="orderStatus" sortable />

          <Column header="Delayed For" field="delayedFor" sortable />
          <Column header="D.Days" field="delayedDays" sortable style={{ width: '0%' }} />
          <Column
            header="E.Time"
            field="estimatedTime"
            sortable
            body={(rd) => {
              const estimatedFollowupTime = new Date(rd.estimatedTime)

              return rd.estimatedTime ? (
                <p
                  className={classNames({
                    'text-red': currentDateTime > estimatedFollowupTime,
                  })}
                >
                  {format(estimatedFollowupTime, 'PPp')}
                </p>
              ) : (
                'NA'
              )
            }}
          />
          <Column
            header="N.Time"
            field="nextFollowupTime"
            sortable
            body={(rd) => {
              const nextFollowupTime = new Date(rd.nextFollowupTime)
              return rd.nextFollowupTime ? (
                <p
                  className={classNames({
                    'text-red': currentDateTime > nextFollowupTime,
                  })}
                >
                  {format(nextFollowupTime, 'PPp')}
                </p>
              ) : (
                'NA'
              )
            }}
          />
          <Column
            header="Action"
            field="action"
            body={(rd) => {
              const estimateFollowupTime = new Date(rd.estimatedTime)
              const nextFollowupTime = new Date(rd.nextFollowupTime)
              const items = [
                {
                  label: rd.isShipmentDelayEnabled ? 'Disable Tracking' : 'Enable Tracking',
                  icon: rd.isShipmentDelayEnabled ? 'pi pi-eye-slash' : 'pi pi-eye',
                  command: async () => {
                    const res = await updateOrderProductShipmentDelays({
                      id: rd.orderProductId,
                      trackingStatus: rd.isShipmentDelayEnabled ? 0 : 1,
                    })
                    if (res) {
                      if (res.status === 200) {
                        handleTrackingSwitch(rd.orderProductId, !rd.isShipmentDelayEnabled)
                      }
                    }
                  },
                },
              ]
              return (
                <div className="p-d-flex gap-1by2 p-ai-center">
                  <SplitButton onClick={() => actionDetails(rd)} label="Notes" model={items} />
                </div>
              )
            }}
          />
          {/* <Column
            style={{ width: '0' }}
            header="Follow"
            body={(rd) => {
              return (
                <div className="p-d-flex p-ai-center gap-1by2">
                  <Checkbox
                    disabled={rd.isFollowupDone}
                    checked={rd.isFollowupDone ? true : false}
                    onChange={() => {
                      handleObjChange(setAddFollowUpForProductRecord, 'opid', rd.orderProductId)
                      handleObjChange(setAddFollowUpForProductRecord, 'status', rd.delayedFor)
                      setAddFollowupForProductDialogVisible(true)
                    }}
                  />
                </div>
              )
            }}
          /> */}
        </DataTable>
      </div>
      <OrderNoteDialog
        notesData={notesData}
        setNotesData={setNotesData}
        hideNotes={hideNotes}
        handleNotesDataChange={handleNotesDataChange}
        addOrderNote={addOrderFollowUpNote}
        deleteOrderNote={deleteOrderFollowUpNote}
        editOrderNote={editOrderFollowUpNote}
        orderProductFollowupRecord={orderProductFollowupRecord}
        setOrderProductFollowupRecord={setOrderProductFollowupRecord}
        isFollowupRecordsVisible={true}
        setDelayedOrderProductecords={setDelayedOrderProductecords}
      />
      <ShipperShippingTimeDialog pathname="/orders/followup" />
      <Dialog
        header="Shipper Timelines"
        visible={dialogVisible}
        // className="max-w-85"
        style={{ width: '60%' }}
        onHide={() => {
          setDialogVisible(false)
        }}
        footer
      >
        <div>
          <DataTable
            ref={dt}
            value={shipperTTRecords}
            filters={shipperTTTableFilters}
            showGridlines
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[10, 25, 50, 100]}
            className="datatable-responsive"
            columnResizeMode="fit"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} shipper timelines"
            emptyMessage="No shipper timeline found."
            loading={shipperTTTableLoading}
            rowHover
          >
            <Column
              header="Shipper"
              field="shipper"
              sortable
              body={(rd) => {
                return (
                  <Link
                    // target="_blank"
                    to={`/website/logistics/?edit_st_code=${rd.shipper}`}
                  >
                    {rd.shipper}
                  </Link>
                )
              }}
            />
            <Column
              header="Shipping Time"
              field="minShippingTime"
              body={(rd) => rd.minShippingTime + ' - ' + rd.maxShippingTime + ' days'}
            />
            <Column
              header="Tracking Time"
              field="minTrackingTime"
              body={(rd) => rd.minTrackingTime + ' - ' + rd.maxTrackingTime + ' days'}
            />
            <Column
              header="Tracking Live Time"
              field="minTrackingLiveTime"
              body={(rd) => rd.minTrackingLiveTime + ' - ' + rd.maxTrackingLiveTime + ' days'}
            />
            <Column
              header="Delivery Time"
              field="minDeliveredTime"
              body={(rd) => rd.minDeliveredTime + ' - ' + rd.maxDeliveredTime + ' days'}
            />
          </DataTable>
        </div>
      </Dialog>
      <Dialog
        header="Follow up details"
        // visible={orderProductFollowupRecord.visible}
        className="w-60 max-w-85"
        onHide={hideOrderProductFollowupRecord}
        footer={
          <Button
            label="Cancel"
            className="p-button-text"
            icon="pi pi-times"
            onClick={hideOrderProductFollowupRecord}
          />
        }
      >
        {/* <div className="card p-shadow-3">
          <h4 className="p-d-flex p-jc-between p-ai-center">
            <span>Followup Timeline</span>
          </h4>
          <hr />
          {orderProductFollowupRecord?.records?.length > 0 ? (
            <div className="widget-timeline">
              <div className="timeline-header p-d-flex p-jc-between p-ai-center"></div>
              <div className="timeline-content">
                <Timeline
                  value={orderProductFollowupRecord?.records}
                  content={content}
                  className="custimized-timeline"
                />
              </div>
            </div>
          ) : (
            <h5 className="p-text-center">
              <i className="pi pi-exclamation-circle p-mx-2" />
              No Followup History
            </h5>
          )}
        </div> */}
      </Dialog>
    </div>
  )
}
